Vue.component("FullscreenVideo", {
    mixins: [SceneContentComponentMixin],
    template: `
        <transition name="fadeinvideo">
            <div class="FullscreenVideo__wrapper" v-if="videoplayerready" @click="playVideo">
                <div class="FullscreenVideo__playbutton" v-if="isVideoPaused">▶</div>
                <div @click="close" class="FullscreenVideo__btnClose" v-if="!hideUI">&times;</div>
                <video
                    id="FullscreenVideo__video"
                    :autoplay="autoplay"
                    :controls="controls && !hideUI"
                    :mute="mute"
                    :loop="loop"
                    playsinline
                    webkit-playsinline
                    type="video/mp4"
                    :src="videoURL"
                    :poster="imgURL"
                    @contextmenu.prevent=""
                ></video>
            </div>
        </transition>
    `,
    data() {
        return {
            img: '',
            video: '',

            // default img directory
            imgDirectory: 'assets/img/',
            videoDirectory: 'assets/video/',

            // Variables that can be set to customize video player component. 
            // These are the default values and can be overwritten in the manifest.
            autoplay: true,
            controls: true,
            mute: false,
            loop: false,
            hideUI: false,

            // Variables for this instance to handle functionality
            videoplayerready: false,
            isVideoPaused: false
        }
    },
    methods: {
        playVideo() {
            // toggle play & pause
            this.isVideoPaused = !this.isVideoPaused;
        }
    },
    computed: {
        videoURL() {
            // set default background video directory
            return this.videoDirectory + this.video
        },
        imgURL() {
            // set default background img directory
            return this.imgDirectory + this.img
        }
    },
    mounted() {
        // handle intro animation
        this.videoplayerready = true;

        // start/stop video on start if autoplay is enabled
        this.isVideoPaused = !this.autoplay;
    },
})

touchKit.ComponentManager.register("FullscreenVideo", {singleton: true});;
Vue.component("SidePanel", {
    mixins: [SceneContentComponentMixin],
    template: `
        <transition :name="sidepanelEntranceName">
            <div class="side-panel" :style="{right: align == 'right' ? 0 : 'auto'}" v-if="open">
                <div class="side-panel__top">
                    <h1 class="side-panel__title">Side Panel Example</h1>
                    <div @click="close" class="side-panel__btnClose">&times;</div>
                </div>
                
                <ul class="side-panel__navigation"> 
                    <li v-for="(nav, i) in slides"
                    :class="{'side-panel__navigation--active': activetab == i}" 
                    @click="changeTab(i)">{{nav.title}}</li>
                </ul>
                
                <transition-group :name="contentTransitionName" tag="section" class="side-panel__content">
                    <div v-for="(content, i) in slides"
                    v-show="activetab == i"
                    :key="content.title">
                    
                        <img class="side-panel__content__poster" v-if="content.poster.endsWith('jpg') || content.poster.endsWith('png')" :src="content.poster">
                        <video class="side-panel__content__poster" autoplay loop type="video/mp4" v-if="content.poster.endsWith('mp4')">
                            <source :src="content.poster">
                        </video>
                        
                        <section class="side-panel__content__text">
                            <h2 v-html="content.title"></h2>
                            <p v-html="content.text"></p>
                        </section> 
                    </div>
                </transition-group>
            </div>
        </transition>
    `,
    data() {
        return {
            activetab: 0,

            // content
            slides: [],
            
            // customization variables
            align: "left",

            // variables for this instance of the sidepanel
            open: false,
            sidepanelEntranceName: "sidepanelentranceleft",
            contentTransitionName: "contentslideright"
        }
    },
    mounted() {
        this.animateEntrance();
    },
    methods: {
        animateEntrance() {
            // animate entrance of component based on which side of the scene it enters.
            this.sidepanelEntranceName = this.align == "left" ? "sidepanelentranceleft" : "sidepanelentranceright"
            this.open = true;
        },
        changeTab(i) {
            // animate change in componentm slides based on what direction the next slide is in.
            this.contentTransitionName = this.activetab < i ? "contentslideright" : "contentslideleft";
            this.activetab = i;
        },
    }
})

touchKit.ComponentManager.register("SidePanel", {singleton: false});;
Vue.component("SplitContent", {
    mixins: [SceneContentComponentMixin],
    template: `
        <transition name="fadeinvideo">
            <div class="split-content" v-if="contentReady" :class="{'split-content--flipped': flipped}">
                <div class="split-content__mediawrapper">
                    <img v-if="media.endsWith('jpg') || media.endsWith('png')" :src="media">
                    <video id="split-content__video" autoplay loop type="video/mp4" v-if="media.endsWith('mp4')">
                        <source :src="media">
                    </video>
                    <div class="split-content__mediawrapper__fullscreen" 
                    v-if="allowfullscreen"
                    @click="showFullscreen">
                        <img src="assets/img/ui/fullscreen.png">
                    </div>
                </div>

                <div class="split-content__content_wrapper">
                    <div @click="close" class="split-content__btnClose">&times;</div>

                    <transition-group :name="contentTransitionName"> 
                        <section class="split-content__content" 
                            v-for="(content, i) in slides" 
                            :key="content.mainTitle"
                            v-show="activetab == i">
                            <h2 v-if="content.subTitle" v-html="content.subTitle"></h2>
                            <h1 v-html="content.mainTitle"></h1>
                            <p v-html="content.text"></p>
                        </section>
                    </transition-group>

                    <ul class="split-content__content_navigation">
                        <li v-for="(nav, i) in slides"
                        :class="{'split-content__content_navigation--active': activetab == i}" 
                        @click="changeTab(i)">
                            <span>{{nav.menuTitle ? nav.menuTitle : nav.mainTitle}}</span>
                        </li>
                    </ul>
                </div>
            </div>
        </transition>
    `,
    data() {
        return {
            // variables for this instance of the split content
            contentReady: false,
            contentTransitionName: "contentslideright",
            activetab: 0,
            
            //content
            slides: [],
            
            //customization variables from manifest.
            flipped: false,
            allowfullscreen: false,
            media: ''
        }
    },
    mounted() {
        // hook for intro transition
        this.contentReady = true;
    },
    methods: {
        changeTab(i) {
            // animate change in componentm slides based on what direction the next slide is in.
            this.contentTransitionName = this.activetab < i ? "contentslideright" : "contentslideleft";
            this.activetab = i;
        },
        showFullscreen() {
            // show video in fullscreen. just html5 fullscreen.
            let vid = document.getElementById("split-content__video")
            if (vid.requestFullscreen) {
                vid.requestFullscreen();
            } else if (vid.mozRequestFullScreen) {
                vid.mozRequestFullScreen();
            } else if (vid.webkitRequestFullscreen) {
                vid.webkitRequestFullscreen();
            }
        }
    },
})

touchKit.ComponentManager.register("SplitContent", {singleton: true});;


Vue.component("Background", {
    props: ["background"],
    template: `
    <div class="background">

   
        <video
            class="background"
            muted
            autoplay
            loop
            playsinline
            webkit-playsinline
            type="video/mp4"
            :src="videoURL"
            @click="backgroundClicked"
            @dblclick="fullscreen"
            @contextmenu.prevent=""
        ></video>

        <!-- 
            removed the poster from the video and replaced it with this, so we can use 
            the decoded image from the cache.
        -->
        <div class="poster background" v-if="!videoPlaying"></div>

    </div>

        
    `,
    data () {
        return {
            //default img and video directory for backgrounds for less clutter in manifest.
            imgDirectory: config.BACKGROUND_IMAGE_DIRECTORY,
            videoDirectory: config.BACKGROUND_VIDEO_DIRECTORY,
            videoPlaying: false,
            currentPoster: "",
        }
    },

   
    mounted () {

        this.videoPlaying = false;
        console.log("Background MOUNTED", this.imgURL)

        // The first time we load the application
        // this page will be shown behind the loader, and
        // when it attempts to request its assets they are not guaranteed to 
        // be ready. 
        // So we listen to the IMAGE_CACHE_READY event the first time around
        // so we can create our poster image
        window.app.radio.$on("IMAGE_CACHE_READY", ()=> {
            console.log("IMAGE_CACHE_READY", this.videoPlaying, this.imgURL)
            this.createPoster();
        })

        // Then subsequential changes of the scene we can just 
        if("ImageCache" in window.app) {
            window.app.ImageCache.done().then(()=> {
                this.createPoster();
            })
        }
        
        var video =  this.$el.querySelector("video");
        this.eventListeners = {};
        this.eventListeners["playing"] = () => {
            this.videoPlaying = true;
        }

        video.addEventListener("playing",  this.eventListeners["playing"]); 
    },

    destroyed () {
        var video =  this.$el.querySelector("video");
        Object.keys(this.eventListeners).forEach(event => {
            video.removeEventListener(event,  this.eventListeners[event]);
        })
    },

    computed: {
        videoURL() {
            return this.videoDirectory + this.background.video
        },
        imgURL() {
            return this.imgDirectory + this.background.img
        }
    },
    methods: {
        fullscreen() {
            this.$emit("fullscreen")
        },
        backgroundClicked() {
            this.$emit("closeAllContent")
        },
        createPoster () {
            // SHOW IMAGE...
            // if the imgUrl is correctly formed (the component will get its data sat after it have been created)
            // and the video is not playing
            if(/(\.jpg|\.png)/.test(this.imgURL) && !this.videoPlaying) {
                console.log("creating poster");
                this.currentPoster = this.imgURL;
                var img = app.ImageCache.getAsset(this.imgURL).content;
                Array.from(this.$el.querySelector(".poster").children).forEach(o => o.remove())
                this.$el.querySelector(".poster").appendChild(img);
            }
        }
    },
});
Vue.component("Hotspot", {
    props: ["hotspot", "index", "settings", "resetHotspot", "activeHotspot"],
    template: `
    <transition :name="hotspotEntranceType">
        <li class="hotspot" 
            v-if="hotspotShown"
            :class="[getHotspotType, {'hotspot--flipped': flipped}, {'hotspot--notext': hotspot.title == ('' || undefined)}, {'hotspot--active': isActiveHotspot}]"
            :style="[{left: hotspot.x + 'px', top: hotspot.y + 'px'}, inactiveHotspot]"
            @click="goto(hotspot); onClickAnimation();">

            <div class="hotspot__outline_left"
                :style="getHotspotColor"></div>

            <transition enter-active-class="animated flipInX"
                leave-active-class="animated flipOutX">
                <div class="hotspot__title_wrapper" v-if="showLabel">
                    <h2 :style="getTextColor" v-html="hotspot.title"></h2>
                </div>
            </transition>


            <div class="hotspot__outline_right"
                :style="getHotspotColor"></div>

            <div class="hotspot-button"
                :style="[getContinuousHotspotAnimation, getClickHotspotAnimation, getHotspotColor]">
            </div>
        </li>
    </transition>
`,
    data() {
        return {
            // variables for this instance of the hotspot
            hotspotShown: false,
            minDelay: 2,
            maxDelay: 14,
            animatingClick: false,
            showText: false,

            //default hotspot style values. can be overwritten in manifest.
            hasUniqueStyle: false,
            flipped: false,
            hotspotcolor: '',
            textcolor: '',

            //global variables for hotspots
            hotspotType: this.settings.hotspotType,

            hotspotEntranceType: this.settings.hotspotEntranceType,
            loopAnimationType: this.settings.loopAnimationType,
            clickAnimationType: this.settings.clickAnimationType,

            animationCycleStyle: this.settings.animationCycleStyle
        }
    },
    methods: {
        goto(hotspot) {
            // Call goto() in PageScene
            if (this.isActiveHotspot || this.hotspot.title == ('' || undefined)) {
                this.$emit("goto", hotspot)
            } else {
                this.$emit("makeActiveHotspot", this.index)
            }
        },
        getRandomAnimationDelay(min, max) {
            //return a random value for random animations
            return Math.random() * (max - min) + min;
        },
        onClickAnimation() {
            // set the hotspot to animate as feedback if the hotspot is pressed
            if (this.animatingClick == false)
                this.animatingClick = true;
            setTimeout(() => {
                this.animatingClick = false;
                this.$emit("resetHotspotAnimationCycle");
            }, 1500);
        },
        hotspotEntrance() {
            //handler for the entrance of hotspots when scene is loaded.
            // load in one by one, depending on their position in the hotspots array of the scene
            let delay = 0;
            delay = (this.index * 200) + 200;

            setTimeout(() => {
                this.hotspotShown = true;
            }, delay);
            setTimeout(() => {
                this.showText = true;
            }, delay + 800);
        },
        getUniqueStyle() {
            // determine if hotspot has any specific style specified in the manifest
            this.hasUniqueStyle = this.hotspot.hasOwnProperty("hotspotstyle");
            if (this.hasUniqueStyle) {
                this.setUniqueStyles(this.hotspot.hotspotstyle);
            }
        },
        setUniqueStyles(style) {
            // get specific style for this hotspot from the manifest.

            //is hotspot flipped?
            this.flipped = style.flipped;

            //get type
            style.hasOwnProperty("hotspotType") ? this.hotspotType = style.hotspotType : this.hotspotType;

            //animations
            style.hasOwnProperty('hotspotentrance') ? this.hotspotEntranceType = style.hotspotentrance : this.hotspotEntranceType;
            style.hasOwnProperty("loopanimation") ? this.loopAnimationType = style.loopanimation : this.loopAnimationType;
            style.hasOwnProperty("clickanimation") ? this.clickAnimationType = style.clickanimation : this.clickAnimationType;

            //colors
            style.hasOwnProperty('hotspotcolor') ? this.hotspotcolor = style.hotspotcolor : this.hotspotcolor;
            style.hasOwnProperty('textcolor') ? this.textcolor = style.textcolor : this.hotspotcolor;
        },
        getAnimationCycleStyle() {
            // helper for assigning animation cycle style from the config.js
            switch (this.animationCycleStyle) {
                case "random":
                    return this.getRandomAnimationDelay(this.minDelay, this.maxDelay).toFixed(1) + 's'
                case "pulse":
                    return ((this.index * 0.1) + 1.5) + "s"
            }
        }
    },
    created() {
        this.getUniqueStyle();
    },
    mounted() {
        this.hotspotEntrance();
    },
    computed: {
        getHotspotType() {
            // assign Link as hot spot type if hotspot has any other hotspot children elements in manifest.
            // else assign the given type.
            if (this.hotspot.hotspots == true) {
                return "linkhotspot"
            } else {
                return this.hotspot.type
            }
        },
        getContinuousHotspotAnimation() {
            // assign the looping animation on hotspots, as well as keeping them synced with resetHotspot.
            if (this.resetHotspot == true) {
                return
            } else {
                return {
                    'animation-name': this.loopAnimationType,
                    'animation-iteration-count': 'infinite',
                    'animation-duration': '15s',
                    'animation-delay': this.getAnimationCycleStyle()
                }
            }
        },
        getClickHotspotAnimation() {
            // Assign the click animation when hotspot is clicked.
            if (this.animatingClick) {
                return {
                    'animation-name': this.clickAnimationType,
                    'animation-iteration-count': '1',
                    'animation-duration': '1.5s',
                    'animation-delay': '0s'
                }
            } else {
                return
            }
        },
        getHotspotColor() {
            // assign custom hotspotcolor.
            if (this.hotspotcolor != '') {
                return {
                    'background': this.hotspotcolor
                }
            } else {
                return
            }

        },
        getTextColor() {
            // assign custom textcolor.
            if (this.textcolor != '') {
                return {
                    'color': this.textcolor
                }
            } else {
                return
            }
        },
        isActiveHotspot() {
            if ((this.index == this.activeHotspot || this.hotspotType != "two-step") && this.showText) {
                return true;
            } else {
                return false;
            }
        },
        inactiveHotspot() {
            if(!this.isActiveHotspot && this.hotspotType != "two-step") {
                return {
                    'padding': 0
                }
            }
        },
        showLabel() {
            if (this.hotspot.title != ('' || undefined) && this.isActiveHotspot) { 
                return true
            }
        }
    }
});
Vue.component("LoaderScreen", {
    props: ["visible"],
    template: `    
        <div id="LoaderScreen">
            <transition
                name="custom-classes-transition"
                :enter-active-class="enterAnimation"
                v-on:after-enter="onEnterComplete"
                :leave-active-class="leaveAnimation">
                    <div class="loader-screen__content" v-if="visible">
                        <div class="inner-wrapper">
                            <h1>LOADING {{getLoadProgress()}} </h1>
                            <div class="lds-dual-ring"></div>
                        </div>
                    </div>
            </transition>
        </div>
    `,
    data () {
        return {
            enterAnimation: "animated zoomIn",
            leaveAnimation: "animated zoomOut animation-delay-750",
            progress: 0
        }
    },

    mounted () {
        window.app.radio.$on("LOADER_PROGRESS", (progress) => {
            this.progress = progress;
        })
    },
    methods: {
        onEnterComplete () {},
        getLoadProgress() {
            return this.progress == 0 ? "..." : (this.progress + " %");
        }
    }

});
const PageGeneric = {

    props: ["pageId"],

    data () {
        var objData = touchKit.PageManager.getPage(this.pageId) || { data: { html: ""}}
        return {
            html: objData.data.html
        }
    },

    template: `
        <div class="page" :id="setPageId">
            <div :class="setWrapperClass" v-html="html">
            </div>
        </div>
    `,


    computed: {
        setPageId () {
            return this.pageId + "__page"
        },
        setWrapperClass () {
            return this.pageId + "__page_wrapper"
        }
    }
};
const PageHome = {

    template: `
        <div>
            <h1>Home</h1>
        </div>
    `
};
Vue.component("SimcoLogo", {   


    data () {
        return {
            title: "hello",
        }
    },
    
    template: `
       <h1 style="position:absolute; top: 0; left: 0; z-index: 999; "  @click="alert">SIMCO YOUUUAUUAUAUAU</h1>
    `, 

    mounted () {
        console.log("MENU MOUNTED " , this.$route.path)
    },

    methods: {
        alert () {
            alert(this.title)
        }
    }
});





;
Vue.component("test", {   
    mixins: [SceneContentComponentMixin],

    data () {
        return {
            title: "lorem",
        }
    },
    template: `
        <div class="component test">
            <h1>test content-id: {{contentId}} {{title}}</h1>
        </div>
    `,    
});

touchKit.ComponentManager.register("test", {singleton: true});



;
Vue.component("Focused", {
    props: ["isContentOpen", "settings"],
    template: `
        <transition name="fadein">
            <div id="focused-overlay" 
                v-if="isContentOpen && settings.enabled"
                @click="focusClicked">
            </div>
        </transition>
    `,
    methods: {
        focusClicked() {
            this.$emit("closeAllContent")
        }
    }
});
Vue.component("Logo", {
    props: ["settings"],
    template: `
        <img id="logo" :src="src" :alt="alt" @click="navigateTo" :style="logoPlacement">
    `,
    data() {
        return {
            // variables defined in config.js
            src: this.settings.src,
            alt: this.settings.alttext,
            alignRight: this.settings.alignRight ? this.settings.alignRight : false,
            alignBottom: this.settings.alignBottom ? this.settings.alignBottom : false,
            navigatesTo: this.settings.navigatesTo
        }
    },
    methods: {
        navigateTo () {
            // based on the where the logo should navigate to from config.js
            if(this.navigatesTo == "home") {
                this.home();
            } else if (this.navigatesTo.includes('/scene/')) {
                this.goto(this.navigatesTo);
            } else if (this.navigatesTo.includes('www')) {
                this.openLink(this.navigatesTo);
            }
        },
        home() {
            // call home function in PageScene
            this.$emit("home");
        },
        goto(route) {
            // push route if navigatesto is a route.
            if (this.$router.currentRoute.path !== route)
                this.$router.push(route);
        },
        openLink(url) {
            // open external link if navigatesto is an external url
            window.open(url, "_blank");
        }
    },
    computed: {
        logoPlacement() {
            // assign logo placement based on config.js settings for logo component
            return {
                top: this.alignBottom ? 'auto' : '2%',
                bottom: this.alignBottom ? '9%' : 'auto',

                right: this.alignRight ? '1.5%' : 'auto',
                left: this.alignRight ? 'auto' : '1.5%',
            }
        }
    }
});
// TODO: make good.
Vue.component("MainMenu", {
    props: ["atHomePage", "menuElements", "settings", "isFullscreen"],
    template: `
        <transition name="menuanimation" v-if="settings.enabled">
            <nav id="main-menu" v-show="menuReady" 
                :class="{'main-menu--hidden': menuHidden}"
                :style="[{width: (bigNavElements == 0 && keepWidth) ? 'auto' : 'calc(100% - 60px)'}, menuPlacement]">
                <transition name="menuanimation">
                    <div class="main-menu__showmenu main-menu__showmenu--active" @click="toggleMenu" v-show="menuHidden">
                        <img src="assets/img/ui/arrowup.png">
                    </div>    
                </transition>

                <ul class="main-menu__navigation">
                    <li v-for="nav in menuElements"
                        :class="[setNativeNavClass(nav), isActiveNav(nav), backButtonDisabled(nav), isFullscreenButton(nav)]"
                        :style="individualNavWidth(nav)"
                        @click="handleClick">
                        <span class="main-menu__navigation_title" v-if="nav.title">
                            {{nav.title}}
                            <img class="main-menu__navigation_extlinkimg" src="assets/img/ui/ext-link.png" v-if="nav.type == 'externallink'">
                        </span>  
                        <div class="main-menu__navigation_img" v-else></div>
                        <!--<img :src="'assets/img/ui/' + nav.type + '.png'">-->
                    </li>
                </ul>
            </nav>
        </transition>
    `,
    data() {
        return {
            //customization variables from config.js
            alignRight: this.settings.alignRight ? this.settings.alignRight : false,
            alignBottom: this.settings.alignBottom ? this.settings.alignBottom : true,
            keepWidth: this.settings.keepWidth,
            
            // variables for this instance of the main menu
            menuHidden: false,
            menuReady: false,
            bigNavElements: 0
        }
    },
    created() {
        // do a count for how many big navigation elements there are, to then assign width to them.
        this.findBigNavElements();
    },
    mounted() {
        // intro animation when scene is ready.
        this.menuReady = true;
    },
    methods: {
        handleClick(e) {
            // a click handler based on the classes assigned from setNativeNavClass(). 

            let classlist = e.target.classList

            for (var i = 0; classlist.length > i; i++) {

                    // is internal navigation
                if (classlist[i].slice(0, 11) == "navigateto_") {
                    this.navGoto(classlist[i].substring(11));

                    // is external navigation
                } else if (classlist[i].slice(0, 14) == "extnavigateto_") {
                    this.openLink(classlist[i].substring(14));

                    //function calls
                } else if (classlist[i].substring(11) == "home") {
                    this.home();
                } else if (classlist[i].substring(11) == "back") {
                    this.back();
                } else if (classlist[i].substring(11) == "fullscreen") {
                    this.fullscreen();
                } else if (classlist[i].substring(11) == "togglemenu") {
                    this.toggleMenu();
                }
            }
            //console.log("list of classes for this element" + classlist)
        },
        home() {
            // call home function from pageScene
            this.$emit("home")
        },
        back() {
            // call back function from pageScene
            this.$emit("back");
        },
        fullscreen() {
            // call fullscreen function from pageScene
            this.$emit("fullscreen");
        },
        toggleMenu() {
            // hide / unhide menu
            this.menuHidden = !this.menuHidden;
        },
        openLink(url) {
            // handler for external links, opens in new tab.
            window.open(url, "_blank");
        },
        navGoto(route) {
            // navigates to the navigation elements specified route from the settings.menu object in manifest.json.
            if (this.$router.currentRoute.path !== route)
                this.$router.push(route);
        },
        isActiveNav(nav) {
            // check route to see if this nav element is an active nav element; meaning a check to see if the user is currently in this
            // part of the manifest branch. assigns '--active' class which can be customized through css.
            if (nav.hasOwnProperty("route")) {
                var currentRoute = this.$router.currentRoute.path;
                if (this.atHomePage) return;
                if (touchKit.utils.isChildRouteOf(nav.route, currentRoute)) {
                    return "main-menu__navigation_nav--active"
                }
            }
        },
        setNativeNavClass(nav) {
            // assign classes to the navigation elements based on the settings.menu data in manifest.json.
            // this is then used as hooks for styling and click events.
            let navtype = "main-menu__" + nav.type
            let islink = !nav.type.endsWith("link") ? "main-menu__navigation_smallnav" : "main-menu__navigation_nav"
            let navigatesto = nav.hasOwnProperty("route") ? "navigateto_" + nav.route : ''
            let externalnavigatesto = nav.hasOwnProperty("url") ? "extnavigateto_" + nav.url : ''

            return navtype + " " + islink + " " + navigatesto + " " + externalnavigatesto
        },

        individualNavWidth(nav) {
            // assign width of the wide elements in navigation based on findBigNavElements() count.
            if (nav.type == 'internallink' || nav.type == 'externallink')
                return 'width:calc((100% / ' + this.bigNavElements + ') - ' + ((this.menuElements.length - this.bigNavElements) * 70) / this.bigNavElements + 'px)'
        },

        findBigNavElements() {
            // a count to find how many wide menu elements there are to be used to assign width to them.
            let n = 0;
            for (var i = 0; this.menuElements.length > i; i++) {
                if (this.menuElements[i].type == 'internallink' || this.menuElements[i].type == 'externallink') {
                    n++;
                }
            }
            this.bigNavElements = n;
        },

        backButtonDisabled(nav) {
            // disable back button if the app is at home page.
            if (nav.type == "back" && this.atHomePage) {
                return 'main-menu__back--disabled';
            }
        },

        isFullscreenButton(nav) {
            // a hook for the fullscreen button to change to a "minimize" styling.
            if (this.isFullscreen && nav.type == "fullscreen") {
                return "main-menu__fullscreen--active"
            }
        }
    },
    computed: {
        // place menu based on variables set in config.js
        menuPlacement() {
            return {
                top: this.alignBottom ? 'auto' : '20px',
                bottom: this.alignBottom ? '20px' : 'auto',

                right: this.alignRight ? '30px' : 'auto',
                left: this.alignRight ? 'auto' : '30px',
            }
        }
    },
});
Vue.component("navHeader", {   

    props: ["settings"],

    data () {
        return {}
    },
    template: `
        <div class="nav-header" :style="{ background: contentPage ? 'rgba(0,0,0,0)' : 'rgba(255, 255, 255, 0.68)' }">
            <div class="nav-header__image">
                <img :src="this.settings.headerSrc">
            </div>
                <div v-if="!contentPage">
                    <div v-if="!subPage" class="nav-header__text">
                        <h1>{{this.settings.title}}</h1>
                        <h2>{{this.settings.subTitle}}</h2>
                    </div>
                    <div v-if="subPage" class="nav-header__text">
                        <h2>{{this.settings.title}}</h2>
                        <h1>{{this.settings.subTitle}}</h1>
                    </div>
                </div>

            <div class="nav-header__logo">
                <img :src="this.settings.logoSrc">
            </div>
            <div class="clearfix"></div>
        </div>
    `,
    mounted(){
    },
    computed: {
        contentPage() {
            return this.$route.path.includes(":")
        },
        subPage() {
            return this.$route.path.replace("/scene/", "").split("/").length > 1
        }
    }
});


